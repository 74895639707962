import { render, staticRenderFns } from "./Announce.vue?vue&type=template&id=b9bb7a58&scoped=true&"
import script from "./Announce.vue?vue&type=script&lang=js&"
export * from "./Announce.vue?vue&type=script&lang=js&"
import style0 from "./Announce.vue?vue&type=style&index=0&id=b9bb7a58&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9bb7a58",
  null
  
)

export default component.exports