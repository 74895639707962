<template>
    <div class="container">
        <div v-if="!isMobile" class="pcWrapper">
            <div class="relationsWrapper">
                <div class="bannerWrapper">
                    <img src="../../assets/images/relations/anc_back.png"/>
                    <!-- <div class="bannerTextWrapper">
                      <span class="bannerText1">行业格局已经发生巨变</span>
                      <span class="bannerText2">我们为您提供场景化的</span>
                      <span class="bannerText3">深度行业解决方案</span>
                    </div> -->

                    <div class="bannerTextWrapper">
                        <span class="bannerText1">公司公告</span>
                        <!-- <span class="bannerText3">深度行业解决方案</span> -->
                    </div>
                </div>
                <div class="announcementWrapper">
                    <div class="top_box">
                        <div class="pc_change">
                            <div>
                                <a v-bind:class="{'active':(staic == 'anc')}">公司公告</a>
                                <a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                <a @click.prevent="jump('gov')" v-bind:class="{'active':(staic == 'gov')}">公司治理</a>
                                <a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                <a @click.prevent="jump('acv')" v-bind:class="{'active':(staic == 'acv')}">业绩报告</a>
                                <a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                <a @click.prevent="jump('ser')" v-bind:class="{'active':(staic == 'ser')}">投资者服务</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mapWrapper">
                    <div style="width: 80%;position: relative;margin: 0 auto">
                        <!--              时间选择器-->
                        <el-row :gutter="20" style="margin-top: 30px;margin-right: 10vw">
                            <el-col :span="6">
                                <el-select v-model="table.year" placeholder="选择年份" style="width: 130%">
                                    <el-option
                                            v-for="item in years"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="6">
                                <el-select v-model="table.month" placeholder="选择月份" style="margin-left: 5vw;width:130%">
                                    <el-option
                                            v-for="item in months"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="6">
                                <el-input v-model="table.keyWords" placeholder="请输入关键词"
                                          style="margin-left: 10vw;width:130%"></el-input>
                            </el-col>
                            <el-col :span="6" @click.native="search_data(table)">
                                <el-button style="width: 80%;margin-left: 14vw;background-color: rgba(53, 135, 195, 1);" type="primary">确认</el-button>
                            </el-col>
                        </el-row>
                        <div class="annoce_box">
                            <div
                                    class="annonce_border"
                                    v-for="announcement in announcements"
                                    :key="announcement.id"
                            >
                                <a :href="announcement.url" target="_blank" style="">
                                    <a class="left_box">
                                        <div class="date_box">
                                            <i>{{announcement.date | filter_date}}</i>
                                            <span>{{announcement.date | filter_month}}</span>
                                        </div>
                                    </a>
                                    <div class="content_box">
                                        <span> </span>
                                    </div>
                                    <div class="right_box">
                                        <div class="cell_box">
                                            <div class="cell">
                                                <h3 class="h3">{{ announcement.content }}</h3>
                                                <div class="info">
                                                    <span>证券代码：{{ announcement.Securities_code }}</span>
                                                    <span>证券简称：{{ announcement.Securities_name}}</span>
                                                    <span>公告编号：{{ announcement.annoce_Num}}</span>
                                                </div>
                                            </div>
                                            <div class="pdf_img">
                                                <img src="../../assets/images/relations/pdf.png">
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <!-- 分页-->
                        <div class="block" style="margin-top: 30px;margin-bottom: 30px">
                            <el-pagination
                                    background
                                    @current-change="handleCurrentChang"
                                    :current-page="page.currentPage"
                                    :page-size="page.pageSize"
                                    layout="prev, pager, next"
                                    :total="page.totle">
                            </el-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {isMobile} from "../../utils/index";

    export default {
        data() {
            return {
                table: {
                    year: '',
                    month: '',
                    keyWords: '',
                },
                isMobile: false,

                years: [{
                    value: '2018',
                    label: '2018年'
                }, {
                    value: '2019',
                    label: '2019年'
                }, {
                    value: '2020',
                    label: '2020年'
                }, {
                    value: '2021',
                    label: '2021年'
                }, {
                    value: '2022',
                    label: '2022年'
                }, {
                    value: '2023',
                    label: '2023年'
                }, {
                    value: '2024',
                    label: '2024年'
                }],
                months: [{
                    value: '01',
                    lable: '01'
                }, {
                    value: '02',
                    lable: '02'
                }, {
                    value: '03',
                    lable: '03'
                }, {
                    value: '04',
                    lable: '04'
                }, {
                    value: '05',
                    lable: '05'
                }, {
                    value: '06',
                    lable: '06'
                }, {
                    value: '07',
                    lable: '07'
                }, {
                    value: '08',
                    lable: '08'
                }, {
                    value: '09',
                    lable: '09'
                }, {
                    value: '10',
                    lable: '10'
                }, {
                    value: '11',
                    lable: '11'
                }, {
                    value: '12',
                    lable: '12'
                }],
                activeName: 'second',
                version: "",
                //分页
                page: {
                    currentPage: 1,
                    pageSize: 10,
                    totle: 0,
                },
                datas: "",
                // 显示的数据
                announcements: [],
                search_datas: '',
                staic: '',
                query_type: '',
            };
        },
        filters: {
            // 年月过滤
            filter_month(value) {
                return value.substring(0, 7);
            },
            // 日期过滤
            filter_date(value) {
                return value.substring(8, 10);
            }
        },
        created() {
            this.getData();
            this.query_type = this.$route.query.category;
            console.log("this.query_type", this.query_type);
            this.pg_change(this.query_type);
            window.scrollTo(0,540);
        },
        mounted() {
            this.isMobile = isMobile();
            window.addEventListener("scroll", this.getScroll);
        },
        destroyed() {
            window.removeEventListener("scroll", this.getScroll);
        },
        methods: {
            // 跳转
            jump(type) {
                if (type == "gov") {
                    this.$router.push({path: "/government", query: {category: type}});
                } else if (type == "acv") {
                    this.$router.push({path: "/Achievement", query: {category: type}});
                } else if (type == "ser") {
                    this.$router.push({path: "/Service", query: {category: type}});
                }
            },
            // 跳转后tab标签的改变
            pg_change(type) {
                console.log("static", type)
                this.staic = type;
            },
            // 模糊查询
            search_data(table) {
                console.log("table", table);
                const datas = this.datas;
                let newListData = [];
                let newListData1 = [];
                let newListData2 = [];
                console.log("搜索data", datas);
                datas.forEach(item => {
                    item.years = item.date.substring(0, 4);
                    item.monthes = item.date.substring(5, 7);
                });
                if (table.year != '' && table.month != '' && table.keyWords != '') {
                    for (let i = 0; i < datas.length; i++) {
                        if (datas[i].years.match(table.year)) {
                            newListData.push(datas[i]);
                        }
                    }
                    for (let i = 0; i < newListData.length; i++) {
                        if (newListData[i].monthes.match(table.month)) {
                            newListData1.push(newListData[i]);
                        }
                    }
                    for (let i = 0; i < newListData1.length; i++) {
                        if (newListData1[i].content.match(table.keyWords)) {
                            newListData2.push(newListData1[i]);
                            const data_x = newListData2;
                            this.page.totle = newListData2.length;
                            console.log("newListData1", newListData2);
                            this.announcements = data_x.slice((this.page.currentPage - 1) * this.page.pageSize, this.page.pageSize + ((this.page.currentPage - 1) * this.page.pageSize));

                        }
                    }
                } else if (table.year != '' && table.month != '' && table.keyWords == '') {
                    for (let i = 0; i < datas.length; i++) {
                        if (datas[i].years.match(table.year)) {
                            newListData.push(datas[i]);
                        }
                    }
                    for (let i = 0; i < newListData.length; i++) {
                        if (newListData[i].monthes.match(table.month)) {
                            newListData1.push(newListData[i]);
                            const data_x = newListData1;
                            this.page.totle = newListData1.length;
                            console.log("newListData1", newListData1);
                            this.announcements = data_x.slice((this.page.currentPage - 1) * this.page.pageSize, this.page.pageSize + ((this.page.currentPage - 1) * this.page.pageSize));
                        }
                    }
                } else if (table.year != '' && table.month == '' && table.keyWords != '') {
                    for (let i = 0; i < datas.length; i++) {
                        if (datas[i].years.match(table.year)) {
                            newListData.push(datas[i]);

                        }
                    }
                    for (let i = 0; i < newListData.length; i++) {
                        if (newListData[i].content.match(table.keyWords)) {
                            newListData1.push(newListData[i]);
                            const data_x = newListData1;
                            this.page.totle = newListData1.length;
                            console.log("datas", newListData1);
                            this.announcements = data_x.slice((this.page.currentPage - 1) * this.page.pageSize, this.page.pageSize + ((this.page.currentPage - 1) * this.page.pageSize));
                        }
                    }
                } else if (table.year == '' && table.month != '' && table.keyWords != '') {
                    for (let i = 0; i < datas.length; i++) {
                        if (datas[i].monthes.match(table.month)) {
                            newListData.push(datas[i]);
                        }
                    }
                    for (let i = 0; i < newListData.length; i++) {
                        if (newListData[i].content.match(table.keyWords)) {
                            newListData1.push(newListData[i]);
                            const data_x = newListData1;
                            this.page.totle = newListData1.length;
                            console.log("datas", newListData1);
                            this.announcements = data_x.slice((this.page.currentPage - 1) * this.page.pageSize, this.page.pageSize + ((this.page.currentPage - 1) * this.page.pageSize));
                        }
                    }
                } else if (table.year == '' && table.month == '' && table.keyWords != '') {
                    for (let i = 0; i < datas.length; i++) {
                        if (datas[i].content.match(table.keyWords)) {
                            newListData.push(datas[i]);
                            const data_x = newListData;
                            this.page.totle = newListData.length;
                            this.announcements = data_x.slice((this.page.currentPage - 1) * this.page.pageSize, this.page.pageSize + ((this.page.currentPage - 1) * this.page.pageSize));
                        }
                    }
                } else if (table.year == '' && table.month != '' && table.keyWords == '') {
                    for (let i = 0; i < datas.length; i++) {
                        if (datas[i].monthes.match(table.month)) {
                            newListData.push(datas[i]);
                            const data_x = newListData;
                            this.page.totle = newListData.length;
                            this.announcements = data_x.slice((this.page.currentPage - 1) * this.page.pageSize, this.page.pageSize + ((this.page.currentPage - 1) * this.page.pageSize));
                        }
                    }
                } else if (table.year != '' && table.month == '' && table.keyWords == '') {
                    for (let i = 0; i < datas.length; i++) {
                        if (datas[i].years.match(table.year)) {
                            newListData.push(datas[i]);
                            const data_x = newListData;
                            this.page.totle = newListData.length;
                            this.announcements = data_x.slice((this.page.currentPage - 1) * this.page.pageSize, this.page.pageSize + ((this.page.currentPage - 1) * this.page.pageSize));
                        }
                    }
                }
                if((table.year != '' && table.month != '' && table.keyWords != '') && newListData2.length == "0"){
                    this.open();
                    this.getData();
                }else if(((table.year != '' && table.month != '' && table.keyWords == '') ||
                    (table.year != '' && table.month == '' && table.keyWords != '') ||
                    (table.year == '' && table.month != '' && table.keyWords != '')) && newListData1.length == "0"){
                    this.open();
                    this.getData();
                } else if(newListData.length == "0"){
                    this.open();
                    this.getData();
                }
                // console.log("new数据", this.datas)
                // if (this.announcements.length == "0") {
                //     this.open();
                //     this.getData();
                // }

            },
            //获取数据并处理
            getData() {
                this.$axios.get("/parameter/query").then((res) => {
                    this.datas = res.data.data;
                    const datas = this.datas;
                    this.page.totle = datas.length;
                    console.log("长度:" + this.page.totle);
                    this.announcements = datas.slice((this.page.currentPage - 1) * this.page.pageSize, this.page.pageSize + ((this.page.currentPage - 1) * this.page.pageSize));
                    console.log("条数", (this.page.currentPage - 1) * this.page.pageSize, this.page.pageSize + ((this.page.currentPage - 1) * this.page.pageSize));
                })
            },
            // 分页
            handleCurrentChang(newPage) {
                console.log("页数", newPage)
                this.page.currentPage = newPage;
                const datas = this.datas;
                this.announcements = datas.slice((this.page.currentPage - 1) * this.page.pageSize, this.page.pageSize + ((this.page.currentPage - 1) * this.page.pageSize));
                console.log("条数", (this.page.currentPage - 1) * this.page.pageSize, this.page.pageSize + ((this.page.currentPage - 1) * this.page.pageSize));
            },
            // 切换tab
            handleClick(tab, event) {
                console.log(tab, event);
            },
            // 空数据弹框提示
            open() {
                this.table.month = "";
                this.table.year = "";
                this.table.keyWords = "";
                this.$alert('没有找到对应数据', {
                    confirmButtonText: '确定'
                });
            },
            getScroll() {
                // this.indexNumTop = $("#index_num").offset().top;
                this.scrollTop =
                    window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70
                ) {
                    //设置滚动大于300时的执行内容
                    // console.log(
                    //   "document.documentElement.scrollTop=",
                    //   document.documentElement.scrollTop
                    // );
                    this.$store.commit("TO_AfterScroll");
                } else {
                    this.$store.commit("TO_AfterScroll_false");
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    * {
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .relationsWrapper {
        background-color: #ffffff;

        .bannerWrapper {
            width: 100%;
            position: relative;

            img {
                width: 100%;
                height: auto;
            }

            .bannerTextWrapper {
                position: absolute;
                top: 45%;
                left: 45%;
                display: flex;
                flex-direction: column;

                .bannerText1 {
                    font-size: 30px;
                    text-align: left;
                    font-family: PingFangSC-Medium;
                }

                .bannerText2 {
                    color: rgba(255, 255, 255, 100);
                    font-size: 40px;
                    text-align: left;
                    font-family: PingFangSC-Medium;
                }

                .bannerText3 {
                    color: rgba(255, 255, 255, 100);
                    font-size: 30px;
                    text-align: left;
                    font-family: PingFangSC-Light;
                }
            }
        }

        .announcementWrapper {
            .top_box {
                background-color: #f6f6f6;;
                position: relative;
                padding: 1px 0px;
                height: 60px;
                top: -3px;
            }

            .pc_change {
                position: absolute;
                left: 60%;
                z-index: 1;
            }

            .pc_change div {
                margin-right: 10px;
                position: relative;
                margin-top: 6%;
                cursor: default;

                .active {
                    padding-bottom: 5px;
                    border-bottom: 2px solid #265ea2;
                    color: #265ea2;
                }
            }

            .pc_change a:nth-child(2n-1) {
                text-decoration: none;
                color: #000000;
                font-size: 15px;
                cursor: pointer;
            }

            .pc_change a:nth-child(2) {
                font-size: 15px;
            }

            .pc_change a:nth-child(2n-1):hover {
                padding-bottom: 5px;
                border-bottom: 2px solid #265ea2;
                color: #265ea2;
            }

            .pc_change a:nth-child(2n-1):visited {
                padding-bottom: 5px;
                border-bottom: 2px solid #265ea2;
                color: #265ea2;
            }
        }

        .mapWrapper {
            display: flex;
            flex-direction: column;
            margin: auto;
            padding: 0px;
            /*公司公告*/

            .annoce_box {
                margin-top: 20px;
                width: 100%;
                height: 100%;

                .annonce_border {
                    margin-top: 10px;
                }

                .annonce_border a {
                    background-color: rgba(246, 246, 246, 1);
                    display: flex;
                    width: 100%;;
                    text-decoration: none;
                    color: #333;
                    margin: 0;
                    padding: 0;

                    .left_box {
                        display: flex;
                        flex-direction: column;
                        width: 11%;
                        height: 120px;
                        padding: 20px;
                        position: relative;

                        i {
                            font-style: normal;
                            font-size: 40px;
                            line-height: 1;
                            font-family: 'ziticqtezhanti';
                            display: block;
                            position: relative;
                        }

                        span {
                            position: absolute;
                            left: 20px;
                            right: 20px;
                            bottom: 20px;
                            font-size: 18px;
                            font-family: 'NeutraTextLight';
                            transition: color .3s ease;
                        }
                    }


                }

                a:hover {
                    .left_box {
                        content: '';
                        color: #FFF;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: rgba(53, 135, 195, 1);
                    }
                }

                .right_box {
                    width: calc(100% - 122px);
                    margin-left: 2px;
                    position: relative;
                    padding: 0 0 0 3.125vw;
                }

                .content_box {
                    background-color: #FFF;
                    width: 0.5vw;
                }

                .cell_box {
                    height: 100%;
                    position: relative;
                    text-align: left;
                    margin-top: 1.5%;
                    display: flex;
                }

                .cell_box .cell {
                    width: 75%;
                    display: inline-block;
                    vertical-align: middle;
                }

                .h3 {
                    font-size: 15px;
                    transition: color .3s ease;
                }

                .info {
                    font-size: 70%;
                    margin-bottom: 15px;
                }

                .cell_box .cell span {
                    margin-right: 20px;
                }

                .pdf_img {
                    width: 20%;
                    height: 100%;
                    position: relative;
                    margin-right: 3%;
                    text-align: right;
                }

                .pdf_img img {
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }

</style>
